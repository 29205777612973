@import '@HarrisonKeeling/design-system/layout';
@import '@HarrisonKeeling/design-system/index.css';

body {
    &.ReactModal__Body--open {
        overflow: hidden;
    }

    .product-type-info {
        display: flex;
        flex-direction: column;

        .type {
            font-size: 12px;
            font-weight: bold;
            color: $subtle;
        }
    }

    .product-type-hint {
        display: flex;
        flex-direction: column;
        gap: 8px;
        max-width: 320px;
        padding: 16px;

        .description {
            white-space: pre-wrap;
        }

        .header {
            display: flex;
            flex-direction: column;

            .title {
                margin: 0;
            }
            .subtitle {
                font-size: 12px;
                font-weight: bold;
                color: $subtle;
            }
        }
    }
}

#root {
    .app {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 16px;
        overflow: visible;

        @media print {
            margin: 0;
        }
    }

    &.fixed {
        height: 100%;
        position: absolute;
        width: 100%;

        .app {
            justify-content: flex-start;
            height: 100vh;
            width: 100vw;
        }
    }
}
